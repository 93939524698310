.showcase-button-dark {
  outline: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #f7f7f7 !important;
  flex: 1 1 auto;
  //margin: 10px;
  padding: 10px 15px;
  border: 1px solid #f7f7f7 !important;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  transition: .3s;
  background-color: transparent !important;
  z-index: 1;
  height: initial;
  &:after {
    position: absolute;
    transition: 0.5s;
    content: '';
    width: 0;
    bottom: 0;
    background-color: #f7f7f7 !important;
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
  }
  &:hover {
    cursor: pointer;
    color: black !important;
    visibility: visible !important;
    &:after {
      color: black !important;
      left: -10%;
      width: 120%;
    }
  }
}

.showcase-button-lt {
  @extend .showcase-button-dark;
  border: 1px solid black !important;
  color: black !important;
  &:after {
    background-color: black !important;
  }
  &:hover {
    color: #f7f7f7 !important;
    &:after {
      color: #f7f7f7 !important;
    }
  }
}

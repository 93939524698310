@import "./bootstrap-override";
@import "./sidebar.scss";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Roboto, sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.log-in-background-image {
  background-image: url("/images/office-man1-min.jpg");
  background-size: cover;
  background-position-x: left;

  /*
    Re-position x value so the main contents of image can still be seen.
    Update this if you change the image
  */
  @media screen and (max-width: 1440px) {
    background-position-x: -160px;
  }
}

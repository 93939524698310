.fixed-sidebar {
  position: fixed !important;
}

.sidebar-container {
  width: 240px;
  height: 100vh;
  overflow-y: scroll;
}

@media screen and (min-width: 992px) {
  .header-toggle-icon, .hamburger-menu-helper {
    display: none;
  }
}

//.header-toggle-icon {
//  /* this width value is also used in the SidebarHelper.tsx file */
//  @media screen and (min-width: 992px) {
//    display: none;
//  }
//}

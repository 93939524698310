/** write custom css before the node modules bootstrap.scss @import statement **/

/* colors */
$navMid: #232f3e;
$navDark: #18202c;
$navLink: #b9bcc0;
$materialBlue: #009be5;

$theme-colors: (
  "navMid": $navMid,
  "navDark": $navDark,
  "navLink": $navLink,
  "materialBlue": $materialBlue,
);

/* Button */
$btn-border-radius: 10px;
$btn-padding-x: 15px;

/* Card */
.card {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  transition: background-color 99999s ease-in-out 0s, color 99999s ease-in-out 0s, font-size 99999s ease-in-out 0s !important;
  font-size: 1rem !important;
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.w-md-75 {
  @include media-breakpoint-up(md) {
    width: 75% !important;
  }
}

.w-lg-60 {
  @include media-breakpoint-up(lg) {
    width: 60% !important;
  }
}


